import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <iframe src='https://www.youtube.com/embed/GDYOKO6ni-k'
        frameborder='0'
        allow='autoplay; encrypted-media'
        allowfullscreen
        title='video'
/>
      </header>
    </div>
  );
}

export default App;
